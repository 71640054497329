angular.module('MyHippoCommons.Directives').directive('animatedMultiModal', function () {
    return {
        scope: {
            modals: '=',
            parentClose: '&',
            dotMoveDirection: '<?' // forward, backward, both
        },
        restrict: 'E',
        transclude: true,
        templateUrl: '/modals/animated-multi/animated-multi.html',
        link: function(scope) {
            scope.goToNextModal = function() {
                if(scope.currentModalIndex + 1 >= scope.totalNumberModals){
                    scope.shouldDisplayModals[0]=false;
                    scope.shouldDisplayModals[1]=false;
                    // call the parents close
                    scope.parentClose()("Directive Args");
                    return;
                }
                scope.animateToNextModal();
            };
        },
        controller: function ($scope, $mdDialog, $element, $timeout) {
            $scope.$parent.$watch('modalsToDisplay', function(newValue) {
                if(newValue){
                    $scope.modals = newValue;
                    $scope.setUp();
                }
            });
            $scope.setUp= function (){
                $scope.totalNumberModals = $scope.modals.length;
                $scope.dotMoveDirection = $scope.dotMoveDirection ? $scope.dotMoveDirection : 'both'; // default;
                if($scope.totalNumberModals === 0){
                    // no modals to show, do not show anything
                    $scope.shouldDisplayModals = [false, false];
                }
                else {
                    $scope.currentModalIndex = 0; // used to keep track of the index of the modal we are showing
                    $scope.currentNumberModalShown = 0; // used to keep track of the queue
                    $scope.shouldDisplayModals = [true, false]; // used to display modals index 0 = Singleton-Modal A index 1 = Singleton-Modal B
                    $scope.modalData = [$scope.modals[$scope.currentNumberModalShown], $scope.modals[$scope.currentNumberModalShown + 1]];
                    $scope.modalsArray = [document.getElementById('modalAId'), document.getElementById('modalBId')];
                }
            };
            $scope.animateToNextModal = function () {
                $scope.animateTo($scope.currentModalIndex+1,true);
            };

            $scope.animateTo = function (newIndex,directionOverride) {
                // when we do not want to change index
                const movingInIncorrectDirection  = (newIndex === $scope.currentModalIndex) ||
                    (newIndex > $scope.currentModalIndex && $scope.dotMoveDirection === 'backward') ||
                    (newIndex < $scope.currentModalIndex && $scope.dotMoveDirection === 'forward');
                if (movingInIncorrectDirection && !directionOverride) {
                    return;
                }
                let direction;
                if (newIndex > $scope.currentModalIndex){
                    direction = 'forward';
                }
                else {
                    direction = 'backward';
                }
                //now we support both direction
                const leavingModal = $scope.modalsArray[$scope.currentNumberModalShown % 2];
                // set the next modal to be the newIndex's modal
                $scope.modalData[($scope.currentNumberModalShown + 1) % 2] = $scope.modals[newIndex];
                if(direction === 'backward'){
                    leavingModal.children[0].className = 'singleton-modal-wrapper leave-backward' ;
                }
                else {
                    leavingModal.children[0].className = 'singleton-modal-wrapper leave' ;
                }
                $timeout(function() {
                    leavingModal.children[0].className = 'singleton-modal-wrapper hidden' ;
                    $timeout(function() {
                        // set leaving modal to display false
                        $scope.shouldDisplayModals[$scope.currentNumberModalShown % 2] = !$scope.shouldDisplayModals[$scope.currentNumberModalShown % 2];
                        //update modal info if we have more modals
                        if(newIndex + 1 < $scope.totalNumberModals){
                            $scope.modalData[$scope.currentNumberModalShown % 2] = $scope.modals[newIndex+1];
                        }
                        //     //increment currentNumber of modals shown
                        $scope.currentNumberModalShown =  $scope.currentNumberModalShown + 1;
                        //     //set entering modal to display true
                        $scope.shouldDisplayModals[$scope.currentNumberModalShown % 2] = !$scope.shouldDisplayModals[$scope.currentNumberModalShown % 2];

                        $scope.currentModalIndex = newIndex;
                        //
                    }, 30); //needs a slight delay
                    const enteringModal = $scope.modalsArray[($scope.currentNumberModalShown + 1) % 2];

                    if(direction === 'backward'){
                        enteringModal.children[0].className = 'singleton-modal-wrapper enter-backward';
                    }
                    else {
                        enteringModal.children[0].className = 'singleton-modal-wrapper enter';
                    }

                },500);
            };
        },

    };
});
